.processSteps {
    display: grid;
    gap: 2rem;
  }
  
  .processStep {
    position: relative;
  }
  



/*   
  .connector::after,
  .connector::before {
    content: '';
    position: absolute;
    background-color: #3B82F6;
    z-index: 1;
  }
  
  .connector::after {
    background-image: linear-gradient(to right, #3B82F6 50%, transparent 50%);
    background-size: 0.5rem 0.25rem;
    background-repeat: repeat-x;
  }
  
  .connector::before {
    content: '▶';
    color: #3B82F6;
    font-size: 1rem;
  }
  
  //Default (mobile) - vertical layout 
  .connector::after {
    top: 100%;
    left: 2rem;
    width: 0.25rem;
    height: 2rem;
    background-image: linear-gradient(to bottom, #3B82F6 50%, transparent 50%);
    background-size: 0.25rem 0.5rem;
  }
  
  .connector::before {
    top: calc(100% + 2rem);
    left: 1.5rem;
    transform: translateX(-50%) rotate(90deg);
  }
  
  // Tablet - 2 column layout 
  @media (min-width: 768px) {
    .processSteps {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .connector:nth-child(odd)::after {
      top: 2rem;
      left: 100%;
      width: 2rem;
      height: 0.25rem;
      transform: none;
      background-image: linear-gradient(to right, #3B82F6 50%, transparent 50%);
      background-size: 0.5rem 0.25rem;
    }
  
    .connector:nth-child(odd)::before {
      top: 1.5rem;
      left: calc(100% + 2rem);
      transform: translateY(-50%);
    }
  
    .connector:nth-child(2n)::after {
      top: 100%;
      left: -1rem;
      width: calc(100% + 3rem);
      height: 2rem;
      background-image: 
        linear-gradient(to right, transparent 50%, #3B82F6 50%),
        linear-gradient(to bottom, #3B82F6 50%, transparent 50%);
      background-size: 100% 0.25rem, 0.25rem 0.5rem;
      background-position: bottom left, bottom right;
      background-repeat: no-repeat, repeat-y;
    }
  
    .connector:nth-child(2n)::before {
      top: calc(100% + 2rem);
      left: -1rem;
      transform: translateX(-50%) rotate(90deg);
    }
  
    .connector:nth-child(2n-1):last-child::after,
    .connector:nth-child(2n-1):last-child::before {
      content: none;
    }
  }
  
  // Desktop - 3 column layout 
  @media (min-width: 1024px) {
    .processSteps {
      grid-template-columns: repeat(3, 1fr);
    }
  
    .connector:nth-child(3n-1)::after,
    .connector:nth-child(3n-2)::after {
      top: 2rem;
      left: 100%;
      width: 2rem;
      height: 0.25rem;
      transform: none;
      background-image: linear-gradient(to right, #3B82F6 50%, transparent 50%);
      background-size: 0.5rem 0.25rem;
    }
  
    .connector:nth-child(3n-1)::before,
    .connector:nth-child(3n-2)::before {
      top: 1.5rem;
      left: calc(100% + 2rem);
      transform: translateY(-50%);
    }
  
    .connector:nth-child(3n)::after {
      top: 100%;
      left: -2rem;
      width: calc(300% + 6rem);
      height: 2rem;
      background-image: 
        linear-gradient(to right, transparent 50%, #3B82F6 50%),
        linear-gradient(to bottom, #3B82F6 50%, transparent 50%);
      background-size: 100% 0.25rem, 0.25rem 0.5rem;
      background-position: bottom left, bottom right;
      background-repeat: no-repeat, repeat-y;
    }
  
    .connector:nth-child(3n)::before {
      top: calc(100% + 2rem);
      left: -2rem;
      transform: translateX(-50%) rotate(90deg);
    }
  
    .connector:nth-child(3n-1):nth-last-child(2)::after,
    .connector:nth-child(3n-1):nth-last-child(2)::before,
    .connector:nth-child(3n-2):last-child::after,
    .connector:nth-child(3n-2):last-child::before {
      content: none;
    }
  }
  
  // Hide connectors on last row 
  .connector:last-child::after,
  .connector:last-child::before,
  .connector:nth-last-child(2):nth-child(odd)::after,
  .connector:nth-last-child(2):nth-child(odd)::before,
  .connector:nth-last-child(3):nth-child(3n-2)::after,
  .connector:nth-last-child(3):nth-child(3n-2)::before {
    content: none;
  } */