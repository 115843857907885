.header{
    position: fixed;
    background-color: #fff;
    left: 0;
    position: sticky;
    top: 0;
    transition: background-color .3s;
    width: 100%;
    z-index: 5;
    display: block;

}
.container{
    max-width: 1200px;
    margin: 0 auto;
    
    align-items: center;
    display: flex;
    padding-bottom: 16px;
    padding-top: 16px;
    position: relative;
    padding-right: 24px;
    padding-left: 24px;
}
.image{
    height: 66px;
    width: auto;
    min-width: 200px;
    /* filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%); */
}